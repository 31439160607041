import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';

// components
import SwiperSlider1 from './SwiperSlider1';

// dummy data
import { slides1 } from './data';

// images
import logo from '../assets/images/logo.png';

const AuthLayout = ({ hasSlider, children, bottomLinks }) => {
    return (
        <div className="bg-gradient2 vh-100 d-flex align-items-center justify-content-center pt-1 pb-2">
            <Container className="h-100">
                <Row className="justify-content-center align-items-center h-100">
                    <Col xl={hasSlider ? 12 : 6} md={hasSlider ? 5 : 10} lg={hasSlider ? 5 : 8}>
                        <Card className="h-100">
                            <Card.Body className="p-0 h-100">
                                <Row className="g-0 h-100">
                                    <Col xs={12} md={hasSlider ? 5 : 12} className={`${hasSlider ? 'shadow' : ''} d-flex flex-column justify-content-center`}>
                                        <div className="p-xl-4 p-2">
                                            <div className="mx-auto mb-4 text-center">
                                                <Link to="/" className="d-flex justify-content-center">
                                                    <img
                                                        src={logo}
                                                        alt="logo"
                                                        height="150"
                                                        className="align-self-center"
                                                    />
                                                </Link>
                                            </div>
                                            {children}
                                        </div>
                                    </Col>
                                    {hasSlider && (
                                        <Col md={7} className="d-none d-md-flex align-items-center justify-content-center">
                                            <div className="swiper-container h-100 w-100">
                                                <SwiperSlider1 slides={slides1} />
                                            </div>
                                        </Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </Card>
                        <Row className="mt-3">
                            <Col xs={12} className="text-center">
                                {bottomLinks}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

AuthLayout.defaultProps = {
    hasSlider: false,
};


export default AuthLayout;