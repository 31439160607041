import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';
import axios from '../axiosConfig';
import { Button, Modal } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ptBR from 'date-fns/locale/pt-BR';

registerLocale('pt-BR', ptBR);

const Dashboard = () => {
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [protocolos, setProtocolos] = useState({
        emAnalise: [],
        concluidos: [],
        reprovados: []
    });
    const [showModal, setShowModal] = useState(false);
    const [filtros, setFiltros] = useState({
        protocoloInput: '',
        dataInicial: null,
        dataFinal: null
    });

    useEffect(() => {
        fetchProtocolos();
    }, []);

    const fetchProtocolos = async () => {
        try {
            const [
                protocoloResponse,
                pavimentacaoResponse,
                drenagemResponse,
                hidrologicoResponse,
                levantamentoTopograficoResponse,
                estradaVicinalResponse,
                obraDeArteEspecialResponse,
                ambientalCivilResponse,
                ambientalInfraResponse ,
                saneamentoResponse,
            ] = await Promise.all([
                axios.get('/api/protocolos/meus-protocolos'),
                axios.get('/api/pavimentacao-urbana/meus-protocolos'),
                axios.get('/api/drenagem-urbana/meus-protocolos'),
                axios.get('/api/estudo-hidrologico/meus-protocolos'),
                axios.get('/api/levantamento-topografico/meus-protocolos'),
                axios.get('/api/estrada-vicinal/meus-protocolos'),
                axios.get('/api/obra-de-arte-especial/meus-protocolos'),
                axios.get('/api/ambiental-civil/meus-protocolos'),
                axios.get('/api/ambiental-infra/meus-protocolos'),
                axios.get('/api/saneamento/meus-protocolos')
            ]);

            const protocolosData = [
                ...protocoloResponse.data,
                ...pavimentacaoResponse.data,
                ...drenagemResponse.data,
                ...hidrologicoResponse.data,
                ...levantamentoTopograficoResponse.data,
                ...estradaVicinalResponse.data,
                ...obraDeArteEspecialResponse.data,
                ...ambientalCivilResponse.data,
                ...ambientalInfraResponse.data,
                ...saneamentoResponse.data
            ];

            // Classifique os protocolos de acordo com o status
            setProtocolos({
                emAnalise: protocolosData.filter(p => p.status === 'pendente'),
                concluidos: protocolosData.filter(p => p.status === 'aprovado'),
                reprovados: protocolosData.filter(p => p.status === 'rejeitado')
            });
        } catch (error) {
            console.error('Erro ao buscar protocolos:', error);
        }
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleCreateProtocolo = () => {
        navigate('/select-request-type');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        fetchProtocolos();
    };

    const handleFilterChange = (e) => {
        setFiltros({
            ...filtros,
            [e.target.name]: e.target.value
        });
    };

    const applyFilters = (protocolo) => {
        const { protocoloInput, dataInicial, dataFinal } = filtros;

        const protocoloValido = protocolo.numeroProtocolo || '';
        const dataProtocolo = new Date(protocolo.createdAt);

        const matchesProtocolo = !protocoloInput || protocoloValido.includes(protocoloInput);
        const matchesDataInicial = !dataInicial || dataProtocolo >= dataInicial;
        const matchesDataFinal = !dataFinal || dataProtocolo <= dataFinal;

        return matchesProtocolo && matchesDataInicial && matchesDataFinal;
    };

    const renderProtocolos = (protocolos, title) => (
        <div className="card mb-4">
            <div className={`card-header ${title === 'Protocolos em análise' ? 'bg-warning' : title === 'Protocolos Concluídos' ? 'bg-success' : 'bg-danger'} text-white d-flex align-items-center`}>
                <h5 className="mb-0">{title}</h5>
            </div>
            <div className="card-body">
                {protocolos.filter(applyFilters).length > 0 ? (
                    <div className="table-responsive">
                        <table className="table table-hover table-shared">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Protocolo</th>
                                    <th>Tipo</th>
                                    <th>Nome do Objeto</th>
                                    <th>Data</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {protocolos.filter(applyFilters).map((protocolo, index) => (
                                    <tr key={protocolo._id}>
                                        <td>{index + 1}</td>
                                        <td>{protocolo.numeroProtocolo}</td>
                                        <td>{protocolo.tipo}</td>
                                        <td>{protocolo.nomeObjeto}</td>
                                        <td>{new Date(protocolo.createdAt).toLocaleDateString()}</td>
                                        <td>
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => navigate(`/protocolo/${protocolo._id}`)}
                                            >
                                                Ver detalhes
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <p className="mb-0">Não há {title.toLowerCase()} no momento.</p>
                )}
            </div>
        </div>
    );

    return (
        <>
            <Navbar1 />
            <div className="container mt-4">
                <h2>Dashboard do Usuário</h2>
                <p>Bem-vindo ao seu painel. Aqui você pode ver suas solicitações de projeto, seus status e criar novas solicitações.</p>
                <div className="row mb-4">
                    <div className="col-md-3 form-group">
                        <label htmlFor="protocolo">Protocolo</label>
                        <input
                            type="text"
                            className="form-control"
                            name="protocoloInput"
                            value={filtros.protocoloInput}
                            onChange={handleFilterChange}
                            placeholder="Ex: AMM20240004"
                        />
                    </div>
                    <div className="col-md-3 form-group">
                        <label htmlFor="dataInicial">Data Inicial</label>
                        <DatePicker
                            selected={filtros.dataInicial}
                            onChange={(date) => setFiltros({ ...filtros, dataInicial: date })}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Selecione a data inicial"
                            locale="pt-BR"
                        />
                    </div>
                    <div className="col-md-3 form-group">
                        <label htmlFor="dataFinal">Data Final</label>
                        <DatePicker
                            selected={filtros.dataFinal}
                            onChange={(date) => setFiltros({ ...filtros, dataFinal: date })}
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Selecione a data final"
                            locale="pt-BR"
                        />
                    </div>
                </div>

                <Button onClick={handleCreateProtocolo} className="btn btn-primary mb-4">
                    Criar Nova Requisição
                </Button>

                {renderProtocolos(protocolos.emAnalise, "Protocolos em análise")}
                {renderProtocolos(protocolos.concluidos, "Protocolos Concluídos")}
                {renderProtocolos(protocolos.reprovados, "Protocolos reprovados")}

                <button onClick={handleLogout} className="btn btn-danger mt-3">Logout</button>

                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Criar Nova Requisição</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {/* Conteúdo do modal para criar nova requisição */}
                    </Modal.Body>
                </Modal>
            </div>
            <Footer1 />
        </>
    );
};

export default Dashboard;
