import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';

const ReformaAmpliacaoForm = ({ onSuccess }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    tipoObra: '',
    nomeObjeto: '',
    origemRecurso: '',
    oficio: null,
    matricula: null,
    programaNecessidades: null,
    plantaTopografica: null,
    relatorioFotografico: null,
    plantaEsgoto: null,
    plantaAgua: null,
    infiltracao: null,
    declaracao: null,
    enderecoObra: '',
    nomenclaturaObjeto: '',
    projetoExistente: null,
    combateIncendio: 'Não',
    plantaCombateIncendio: null,
    ensaioSondagem: null,
    issqn: null,
    valor: '',
  });

  const [error, setError] = useState('');

  const formatCurrency = (value) => {
    const numericValue = value.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(numericValue / 100); // Divide por 100 para considerar os centavos
    return formattedValue;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;

    let newValue = value;
    if (name === 'valor') {
      newValue = formatCurrency(value);
    }

    setFormData({
      ...formData,
      [name]: files ? files[0] : newValue,
    });
  };

  const handleNext = () => {
    if (
      step === 1 &&
      (!formData.tipoObra || !formData.nomeObjeto || !formData.oficio)
    ) {
      setError('Preencha todos os campos obrigatórios');
    } else {
      setError('');
      setStep(step + 1);
    }
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const data = new FormData();
        for (const key in formData) {
            if (formData[key] !== null && formData[key] !== '') {
                data.append(key, formData[key]);
            }
        }

        const response = await axios.post('/api/protocolos/reforma-ampliacao', data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.data && response.data.protocolo && response.data.protocolo._id) {
            const createdProtocoloId = response.data.protocolo._id;
            navigate(`/protocolo/${createdProtocoloId}`);
        } else {
            throw new Error('ID do protocolo não foi retornado');
        }
    } catch (error) {
        console.error('Erro ao criar protocolo:', error);
        setError('Erro ao criar protocolo. Por favor, tente novamente.');
    }
};

  const renderTipoObraStep = () => (
    <>
      <h4>Tipo de Obra e Dados Gerais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Selecione o Tipo de Obra</Form.Label>
        <Form.Control
          as="select"
          name="tipoObra"
          value={formData.tipoObra}
          onChange={handleChange}
          required
        >
          <option value="">Selecione...</option>
          <option value="Reforma">Reforma</option>
          <option value="Ampliação">Ampliação</option>
        </Form.Control>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
        <Form.Control
          type="text"
          name="nomeObjeto"
          value={formData.nomeObjeto}
          onChange={handleChange}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
        <Form.Control
          type="file"
          name="oficio"
          onChange={handleChange}
          required
        />
      </Form.Group>
    </>
  );

  const renderArquiteturaStep = () => (
    <>
      <h4>Arquitetura - {formData.tipoObra}</h4>
      {formData.tipoObra === 'Ampliação' && (
        <Form.Group className="mb-3">
          <Form.Label>Planta Topográfica</Form.Label>
          <Form.Control
            type="file"
            name="plantaTopografica"
            onChange={handleChange}
          />
        </Form.Group>
      )}
      <Form.Group className="mb-3">
        <Form.Label>Relatório Fotográfico</Form.Label>
        <Form.Control
          type="file"
          name="relatorioFotografico"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Matrícula/Escritura ou termo de posse do terreno</Form.Label>
        <Form.Control
          type="file"
          name="matricula"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Programa de necessidades</Form.Label>
        <Form.Control
          type="file"
          name="programaNecessidades"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Projeto arquitetônico das edificações existentes</Form.Label>
        <Form.Control
          type="file"
          name="projetoExistente"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Origem do Recurso</Form.Label>
        <Form.Control
          type="text"
          name="origemRecurso"
          value={formData.origemRecurso}
          onChange={handleChange}
          placeholder="Caixa, SINFRA, etc."
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Valor em R$</Form.Label>
        <Form.Control
          type="text"
          name="valor"
          value={formData.valor}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Endereço da Obra</Form.Label>
        <Form.Control
          type="text"
          name="enderecoObra"
          value={formData.enderecoObra}
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Nomenclatura do Objeto conforme convênio</Form.Label>
        <Form.Control
          type="text"
          name="nomenclaturaObjeto"
          value={formData.nomenclaturaObjeto}
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );
   
  const renderCombateIncendioStep = () => (
    <>
      <h4>Combate a Incêndio</h4>
      <Form.Group className="mb-3">
        <Form.Label>A obra existente possui projeto de combate a incêndio?</Form.Label>
        <Form.Control
          as="select"
          name="combateIncendio"
          value={formData.combateIncendio}
          onChange={handleChange}
        >
          <option>Sim</option>
          <option>Não</option>
        </Form.Control>
      </Form.Group>
      {formData.combateIncendio === 'Sim' && (
        <Form.Group className="mb-3">
          <Form.Label>Anexar o projeto de combate a incêndio com ART</Form.Label>
          <Form.Control
            type="file"
            name="plantaCombateIncendio"
            onChange={handleChange}
          />
        </Form.Group>
      )}
    </>
  );

  const renderEstruturalStep = () => (
    <>
      <h4>Informações Estruturais</h4>
      <Form.Group className="mb-3">
        <Form.Label>Ensaio de Sondagem do terreno (SPT)</Form.Label>
        <Form.Control
          type="file"
          name="ensaioSondagem"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  const renderOrcamentoStep = () => (
    <>
      <h4>Informações de Orçamento</h4>
      <Form.Group className="mb-3">
        <Form.Label>Declaração de ISSQN com alíquota e base de cálculo</Form.Label>
        <Form.Control
          type="file"
          name="issqn"
          onChange={handleChange}
        />
      </Form.Group>
    </>
  );

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      {step === 1 && renderTipoObraStep()}
      {step === 2 && renderArquiteturaStep()}
      {step === 3 && renderCombateIncendioStep()}
      {step === 4 && renderEstruturalStep()}
      {step === 5 && renderOrcamentoStep()}

      <div className="d-flex justify-content-between mt-4">
        {step > 1 && (
          <Button variant="secondary" onClick={handlePrevious}>
            Voltar
          </Button>
        )}
        {step < 5 && (
          <Button variant="primary" onClick={handleNext}>
            Próxima Fase
          </Button>
        )}
        {step === 5 && (
          <Button variant="primary" type="submit">
            Enviar Solicitação
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ReformaAmpliacaoForm;
