// Path: sgp\src\context\AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

const apiUrl = process.env.REACT_APP_API_URL;

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkLoggedIn = async () => {
            const token = localStorage.getItem('authToken');
            if (token) {
                try {
                    const res = await axios.get(`${apiUrl}/api/auth/user`, {
                        headers: { 'Authorization': `Bearer ${token}` },
                    });
                    setUser(res.data);
                } catch (err) {
                    console.error('Error fetching user data:', err);
                    localStorage.removeItem('authToken');
                }
            }
            setLoading(false);
        };
        checkLoggedIn();
    }, []);

    const login = async (email, password) => {
        const res = await axios.post(`${apiUrl}/api/auth/login`, { email, password });
        localStorage.setItem('authToken', res.data.token);
        setUser(res.data.user);
        return res.data.user;
    };

    const logout = () => {
        localStorage.removeItem('authToken');
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
