import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';

const SaneamentoForm = () => {
    const [step, setStep] = useState(1);
    const [subtipoProjeto, setSubtipoProjeto] = useState('');
    const [formData, setFormData] = useState({
        nomeObjeto: '',
        oficioSolicitacao: null,
        pmsb: null,
        plantaPlanialt: null,                // Planta Planialtimétrica
        tipoCaptacao: '',                   // Tipo de Captação (superficial, subterrânea, etc.)
        estruturaTecnica: '',               // Descrição da Estrutura Técnica
        alternativasLocacionais: null,      // Alternativas Locacionais
        mapaSituacao: null,                 // Mapa de Situação
        escrituraTerreno: null,             // Escritura do Terreno
        caracterizacaoManancial: null,      // Caracterização do Manancial

        // Campos específicos para captação de água
        identificacaoCorpoHidrico: '',      // Identificação do Corpo Hídrico
        coordenadasPontoCaptacao: '',       // Coordenadas do Ponto de Captação
        identificacaoSistemaCaptacao: '',   // Identificação do Sistema de Captação
        dadosTecnicosCaptacao: '',          // Dados Técnicos da Captação
        cadastroLigaçõesAtivas: null,

        // Campos específicos para esgotamento sanitário
        identificacaoCorpoHidricoReceptor: '', // Identificação do Corpo Hídrico Receptor
        coordenadasPontoLancamento: '',        // Coordenadas do Ponto de Lançamento
        dadosTecnicosSistema: '',              // Dados Técnicos do Sistema
        fluxogramaSistemaTratamento: null,     // Fluxograma do Sistema de Tratamento
        boletimAnaliseEfluente: null,          // Boletim de Análise do Efluente
    });

    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleNext = () => {
        if (step === 1 && subtipoProjeto === '') {
            setError('Por favor, selecione um subtipo de projeto.');
        } else {
            setError('');
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const data = new FormData();
            data.append('tipo', 'Saneamento'); // Valor fixo para tipoProjeto
            data.append('subtipoProjeto', subtipoProjeto); // Valor dinâmico do subtipo
            for (const key in formData) {
                if (formData[key] !== null && formData[key] !== '') {
                    data.append(key, formData[key]);
                }
            }

            const response = await axios.post('/api/protocolos/saneamento', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data && response.data.protocolo && response.data.protocolo._id) {
                const createdProtocoloId = response.data.protocolo._id;
                navigate(`/protocolo/${createdProtocoloId}`);
            } else {
                throw new Error('ID do protocolo não foi retornado');
            }
        } catch (error) {
            console.error('Erro ao criar protocolo:', error);
            setError('Erro ao criar protocolo. Por favor, tente novamente.');
        }
    };

    const renderTipoProjetoStep = () => (
        <>
            <h4>Seleção do Subtipo de Projeto</h4>
            <Form.Group className="mb-3">
                <Form.Label>Subtipo de Projeto</Form.Label>
                <Form.Control
                    as="select"
                    name="subtipoProjeto"
                    value={subtipoProjeto}
                    onChange={(e) => setSubtipoProjeto(e.target.value)}
                    required
                >
                    <option value="">Selecione o subtipo de projeto</option>
                    <option value="estacaoTratamentoAgua">Estação de Tratamento de Água</option>
                    <option value="sistemaEsgotamentoSanitario">Sistema de Esgotamento Sanitário</option>
                    <option value="ampliacaoRedeAgua">Ampliação de Rede de Água</option>
                    <option value="estacaoTransbordo">Estação de Transbordo de Resíduos Sólidos Domiciliares</option>
                    <option value="sistemaAbastecimentoAgua">Sistema de Abastecimento de Água</option>
                    <option value="ligacoesDomiciliares">Ligações Domiciliares</option>
                    <option value="sistemaTratamentoEsgoto">Sistema de Tratamento de Esgoto</option>
                    <option value="reservatorioAgua">Reservatório de Água</option>
                </Form.Control>
            </Form.Group>
        </>
    );

    const renderDadosGerais = () => (
        <>
            <h4>Dados Gerais</h4>
            <Form.Group className="mb-3">
                <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
                <Form.Control
                    type="text"
                    name="nomeObjeto"
                    value={formData.nomeObjeto}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="oficioSolicitacao"
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>PMSB – Plano Municipal de Saneamento Básico (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="pmsb"
                    onChange={handleChange}
                    required
                />
            </Form.Group>
        </>
    );

    const renderEstacaoTratamentoAguaStep = () => (
        <>
            <h4>Estação de Tratamento de Água</h4>
            <Form.Group className="mb-3">
                <Form.Label>Planta Planialtimétrica (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaPlanialt"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Tipo de Captação de Água para Abastecimento Público</Form.Label>
                <Form.Control
                    type="text"
                    name="tipoCaptacao"
                    value={formData.tipoCaptacao}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Estrutura Técnico-Administrativa do Sistema</Form.Label>
                <Form.Control
                    type="text"
                    name="estruturaTecnica"
                    value={formData.estruturaTecnica}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Alternativas Locacionais (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="alternativasLocacionais"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Mapa de Situação da Área Proposta (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="mapaSituacao"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Fotocópia da Escritura do Terreno (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="escrituraTerreno"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Caracterização do Manancial para Captação (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="caracterizacaoManancial"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderSistemaEsgotamentoSanitarioStep = () => (
        <>
            <h4>Sistema de Esgotamento Sanitário</h4>
            <Form.Group className="mb-3">
                <Form.Label>Planta Planialtimétrica (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaPlanialt"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta com Arruamento, Lotes por Quadra e Cadastramento das Ligações de Água (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaArruamento"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Cadastro das Redes Existentes (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="cadastroRedes"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta As Built do Sistema/ Rede Coletora Existente (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaAsBuilt"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Diagnóstico Situacional da Rede Coletora e Estação de Tratamento Existente (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="diagnosticoSituacao"
                    value={formData.diagnosticoSituacao}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Fotocópia da Escritura do Terreno ou Termo de Posse Registrado em Cartório (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="escrituraTerreno"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Mapa de Localização da Área do Empreendimento (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="mapaLocalizacao"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Alternativas Locacionais para Implantação da ETE e Emissários (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="alternativasLocacionaisETE"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Caracterização do Manancial Receptor do Efluente (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="caracterizacaoManancialReceptor"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderAmpliacaoRedeAguaStep = () => (
        <>
            <h4>Ampliação de Rede de Água</h4>
            <Form.Group className="mb-3">
                <Form.Label>Planta Planialtimétrica (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaPlanialt"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta Cadastral com Cotas, Curvas de Nível, Diâmetros e Comprimentos das Tubulações (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaCadastral"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Informações sobre o Sistema Existente (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="infoSistemaExistente"
                    value={formData.infoSistemaExistente}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Especificação da Rede de Energia no Local e Capacidade do Transformador Existente (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="especificacaoRedeEnergia"
                    value={formData.especificacaoRedeEnergia}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Coordenadas Geográficas de Todos os Itens Relacionados (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="coordenadasGeograficas"
                    value={formData.coordenadasGeograficas}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Relação de Beneficiários e Respectivos Endereços (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="relacaoBeneficiarios"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta As Built do Sistema/ Rede de Distribuição Existente (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaAsBuiltAgua"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderEstacaoTransbordoStep = () => (
        <>
            <h4>Estação de Transbordo de Resíduos Sólidos Domiciliares</h4>
            <Form.Group className="mb-3">
                <Form.Label>Fotocópia da Escritura do Terreno ou Termo de Posse Registrado em Cartório (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="escrituraTerreno"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta Topográfica Conforme NBR 13.133 (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaTopograficaNBR"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Croqui da Área com as Dimensões do Terreno (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="croquiAreaDWG"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta Cadastral Indicando Uso do Solo e Construções Existentes nas Imediações (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaCadastralUsoSolo"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Relatório Fotográfico das Áreas (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="relatorioFotografico"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Coordenadas Geográficas da Área Escolhida e Imagem do Google Earth (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="coordenadasGeograficasGoogle"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Declaração de Uso e Ocupação do Solo e Água (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="declaracaoUsoOcupacao"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Apresentação de Projetos: Arquitetônico e Hidrossanitário com Memorial Descritivo e RRT/ART (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="apresentacaoProjetos"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderSistemaAbastecimentoAguaStep = () => (
        <>
            <h4>Sistema de Abastecimento de Água</h4>
            <Form.Group className="mb-3">
                <Form.Label>Planta Planialtimétrica (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaPlanialt"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta Cadastral com Diâmetros e Comprimentos das Tubulações, Locação de Poços e Reservatórios (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaCadastralAgua"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Informações sobre o Sistema Existente: Tipo de Captação de Água, Quantidade Captada e Tratada, etc. (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="infoSistemaExistente"
                    value={formData.infoSistemaExistente}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Especificação da Rede de Energia no Local e Capacidade do Transformador Existente (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="especificacaoRedeEnergia"
                    value={formData.especificacaoRedeEnergia}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Coordenadas Geográficas de Todos os Itens Relacionados (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="coordenadasGeograficasAgua"
                    value={formData.coordenadasGeograficasAgua}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Relação de Beneficiários e Respectivos Endereços (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="relacaoBeneficiarios"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta As Built do Sistema/ Rede de Distribuição Existente (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaAsBuiltAgua2"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderLigacoesDomiciliaresStep = () => (
        <>
            <h4>Ligações Domiciliares</h4>
            <Form.Group className="mb-3">
                <Form.Label>Cadastro das Ligações Domiciliares Ativas (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="cadastroRedes"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Declaração de ISSQN com Alíquota e Base de Cálculo (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="declaracaoISSQN"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Cópia Autenticada da Carteira de Identidade e CPF do Prefeito (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="copiaIdentidadePrefeito"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Comprovante de Abertura de Conta Corrente Específica para o Convênio (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="comprovanteContaCorrente"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Certidão Negativa de Débitos – CND junto ao INSS ou Cópia das Três Últimas Guias de Recolhimento (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="certidaoNegativaINSS"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Certificado de Regularidade do FGTS (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="certificadoRegularidadeFGTS"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Certidão Negativa de Débitos da Fazenda Pública Estadual (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="certidaoNegativaEstadual"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Certidão Negativa do Tribunal de Contas do Estado (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="certidaoNegativaTCE"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderSistemaTratamentoEsgotoStep = () => (
        <>
            <h4>Sistema de Tratamento de Esgoto</h4>
            <Form.Group className="mb-3">
                <Form.Label>Planta Planialtimétrica (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaPlanialt"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta com Arruamento, Lotes por Quadra e Cadastramento das Ligações de Água (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaArruamento"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Cadastro das Redes Existentes (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="cadastroRedes"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta As Built do Sistema/ Rede Coletora Existente (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaAsBuiltEsgoto"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Diagnóstico Situacional da Rede Coletora e Estação de Tratamento Existente (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="diagnosticoSituacaoEsgoto"
                    value={formData.diagnosticoSituacaoEsgoto}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Fotocópia da Escritura do Terreno ou Termo de Posse Registrado em Cartório (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="escrituraTerreno"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Mapa de Localização da Área do Empreendimento (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="mapaLocalizacaoEsgoto"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Alternativas Locacionais para Implantação da ETE e Emissários (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="alternativasLocacionaisETE2"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Caracterização do Manancial Receptor do Efluente (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="caracterizacaoManancialReceptor2"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderReservatorioAguaStep = () => (
        <>
            <h4>Reservatório de Água</h4>
            <Form.Group className="mb-3">
                <Form.Label>Ensaio de Sondagem do Terreno (SPT) contendo a Descrição do Tipo do Solo (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="ensaioSondagem"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Declaração de ISSQN com Alíquota e Base de Cálculo (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="declaracaoISSQN"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Declaração de Melhor Opção Econômica (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="declaracaoOpcaoEconomica"
                    value={formData.declaracaoOpcaoEconomica}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Declaração de Responsabilidade (quando aplicável) (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="declaracaoResponsabilidade"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>QCI (Quadro de Composição do Investimento) (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="qci"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Fotocópia da Escritura do Terreno (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="escrituraTerreno"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta Topográfica Conforme NBR 13.133 (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaTopograficaReservatorio"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Coordenadas Geográficas do Terreno (Texto)</Form.Label>
                <Form.Control
                    type="text"
                    name="coordenadasGeograficasReservatorio"
                    value={formData.coordenadasGeograficasReservatorio}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta do Município Atualizada em DWG (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaMunicipioReservatorio"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Croqui do Terreno com as Medidas e Área do Terreno (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="croquiTerrenoReservatorio"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderSteps = () => {
        switch (subtipoProjeto) {
            case 'estacaoTratamentoAgua':
                return renderEstacaoTratamentoAguaStep();
            case 'sistemaEsgotamentoSanitario':
                return renderSistemaEsgotamentoSanitarioStep();
            case 'ampliacaoRedeAgua':
                return renderAmpliacaoRedeAguaStep();
            case 'estacaoTransbordo':
                return renderEstacaoTransbordoStep();
            case 'sistemaAbastecimentoAgua':
                return renderSistemaAbastecimentoAguaStep();
            case 'ligacoesDomiciliares':
                return renderLigacoesDomiciliaresStep();
            case 'sistemaTratamentoEsgoto':
                return renderSistemaTratamentoEsgotoStep();
            case 'reservatorioAgua':
                return renderReservatorioAguaStep();
            default:
                return null;
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            {step === 1 && renderTipoProjetoStep()}
            {step === 2 && renderDadosGerais()}
            {step >= 3 && renderSteps()}

            <div className="d-flex justify-content-between mt-4">
                {step > 1 && (
                    <Button variant="secondary" onClick={handlePrevious}>
                        Voltar
                    </Button>
                )}
                {step < 3 && (
                    <Button variant="primary" onClick={handleNext} disabled={!subtipoProjeto && step === 1}>
                        Próxima Fase
                    </Button>
                )}
                {step === 3 && (
                    <Button variant="primary" type="submit">
                        Enviar Solicitação
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default SaneamentoForm;
