import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import axios from '../axiosConfig';

// Import required modules from 'swiper' package
import { Pagination, Autoplay } from 'swiper/modules';

const SwiperSlider1 = () => {
    const [slides, setSlides] = useState([]);
    const swiperRef = useRef(null);

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await axios.get('/api/slide');
                setSlides(response.data);

                // Forçar atualização do Swiper após o carregamento dos slides
                if (swiperRef.current) {
                    swiperRef.current.swiper.update();
                }
            } catch (error) {
                console.error('Error fetching slides:', error);
            }
        };

        fetchSlides();
    }, []);

    const swiperConfig = {
        breakpoints: {
            576: {
                slidesPerView: 1.2,
            },
            768: {
                slidesPerView: 1,
            },
        },
        pagination: { dynamicBullets: true },
        roundLengths: true,
        slidesPerView: 1,
        loop: true,
        spaceBetween: 0,
        autoplay: {
            delay: 3000,
            disableOnInteraction: false, // Não pause o autoplay ao interagir
        },
    };

    return (
        slides.length > 0 && (
            <Swiper
                ref={swiperRef} // Referência ao swiper
                modules={[Pagination, Autoplay]}
                {...swiperConfig}
            >
                {slides.map((slide, index) => (
                    <SwiperSlide key={index.toString()}>
                        <div className="swiper-slide-content">
                            <img src={slide.image} alt={slide.slideTitle} className="slide-image" />
                            <div className="swiper-slide-text">
                                <h5 className="texto">{slide.slideTitle}</h5>
                                <p className="texto">{slide.description}</p>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    );
};


export default SwiperSlider1;
