import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Badge } from 'react-bootstrap';
import PendenciasModal from '../../components/PendenciasModal'; // Import the new modal component
import axios from '../../axiosConfig';

const AdminConstrucaoNovaView = ({ protocolo }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedField, setSelectedField] = useState('');
    const [issues, setIssues] = useState(protocolo.pendingIssues || []);

    useEffect(() => {
        // Atualiza as pendências quando o protocolo é carregado ou atualizado
        setIssues(protocolo.pendingIssues || []);
    }, [protocolo]);

    const handleIssueAdded = async () => {
        try {
            // Fetch the updated protocol to get the latest pending issues
            const response = await axios.get(`/api/protocolos/${protocolo._id}`);
            setIssues(response.data.pendingIssues);
        } catch (error) {
            console.error('Erro ao atualizar as pendências:', error);
        }
    };

    const renderStatusTag = (status) => {
        switch (status) {
            case 'pendente':
                return <Badge bg="warning">Pendente</Badge>;
            case 'respondido':
                return <Badge bg="success">Respondido</Badge>;
            case 'aprovado':
                return <Badge bg="primary">Aprovado</Badge>;
            default:
                return null;
        }
    };

    const renderPendingIssues = (fieldName) => {
        const fieldIssues = issues.filter(issue => issue.fieldName === fieldName);
        return fieldIssues.length > 0 ? (
            <ul>
                {fieldIssues.map((issue, index) => (
                    <li key={index} style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {issue.description} {renderStatusTag(issue.status)}
                            {issue.status === 'pendente'}
                        </div>
                        {issue.status === 'respondido' && issue.response && (
    <div style={{ marginLeft: '20px', marginTop: '5px', color: '#28a745' }}>
        <strong>Resposta:</strong> {issue.response}
        <br />
        <small>Respondido em: {new Date(issue.responseDate).toLocaleString()}</small>
    </div>
)}
{issue.status === 'respondido' && issue.responseFileUrl && (
    <div style={{ marginLeft: '20px', marginTop: '5px' }}>
        <strong>Arquivo enviado:</strong> <a href={issue.responseFileUrl} target="_blank" rel="noopener noreferrer">{issue.responseFileName || 'Visualizar arquivo'}</a>
        <br />
        <small>Respondido em: {new Date(issue.responseDate).toLocaleString()}</small>
    </div>
)}
                    </li>
                ))}
            </ul>
        ) : '';
    };

    const renderDocumentLink = (label, url) => (
        url ? <a href={url} target="_blank" rel="noopener noreferrer">Visualizar documento</a> : 'Arquivo Não informado'
    );

    const renderSection = (title, fields) => (
        <div className="mb-4">
            <h5 className="section-title">{title}</h5>
            <Table striped bordered hover className="custom-table">
                <thead>
                    <tr>
                        <th style={{ width: '450px' }}>Campo</th>
                        <th>Valor</th>
                        <th style={{ width: '550px' }}>Pendências</th>
                        <th style={{ width: '100px' }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map(([label, value], index) => (
                        <tr key={index}>
                            <td><strong>{label}</strong></td>
                            <td>{value || 'Não informado'}</td>
                            <td>{renderPendingIssues(label)}</td>
                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    onClick={() => {
                                        setSelectedField(label);
                                        setShowModal(true);
                                    }}
                                    variant="danger"
                                    size="sm"
                                    style={{ padding: '2px 6px', fontSize: '12px'}}
                                >
                                    Add Pend.
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );

    const getStatusColor = (status) => {
        switch (status) {
            case 'aprovado':
                return '#28a745'; // Verde
            case 'pendente':
                return '#ffc107'; // Amarelo
            case 'rejeitado':
                return '#dc3545'; // Vermelho
            default:
                return '#6c757d'; // Cinza (para status desconhecidos)
        }
    };

    return (
        <div>
            <PendenciasModal
                protocoloId={protocolo._id}
                fieldName={selectedField}
                show={showModal}
                onHide={() => setShowModal(false)}
                onIssueAdded={handleIssueAdded}
            />
            <Card className="mb-4" style={{ backgroundColor: '#007bff', color: 'white' }}>
                <Card.Body>
                    <h2 className="texto">
                        {protocolo.nomeObjeto} - {protocolo.usuario.municipality}
                    </h2>
                </Card.Body>
            </Card>

            <Card className="mb-4" style={{ backgroundColor: getStatusColor(protocolo.status), color: 'white', height: '50px', lineHeight: '30px', padding: '10px 20px' }}>
                <Card.Body className="p-0 d-flex align-items-center">
                    <h4 className="m-0">
                        Status: {protocolo.status.charAt(0).toUpperCase() + protocolo.status.slice(1)}
                    </h4>
                </Card.Body>
            </Card>

            {protocolo.status === 'rejeitado' && protocolo.rejectionJustification && (
                <Card className="mb-4" style={{ backgroundColor: '#dc3545', color: 'white' }}>
                    <Card.Body>
                        <h4>Justificativa da Rejeição</h4>
                        <p>{protocolo.rejectionJustification}</p>
                    </Card.Body>
                </Card>
            )}
            
            <h2>Detalhes do Protocolo - Construção Nova</h2>
            
            {renderSection('Dados Gerais', [
                ['Número do Protocolo', protocolo.numeroProtocolo],
                ['Tipo de Obra', protocolo.tipo || 'Construção Nova'],
                ['Nome da Solicitação', protocolo.nomeObjeto],
                ['Ofício', renderDocumentLink('Ofício', protocolo.oficioUrl)],
                ['Data de Criação', new Date(protocolo.createdAt).toLocaleString()],
                ['Data de Atualização', new Date(protocolo.updatedAt).toLocaleString()],
            ])}

            {renderSection('Dados do Solicitante', [
                ['Nome Completo', protocolo.usuario.fullName],
                ['Email', protocolo.usuario.email],
                ['Cargo', protocolo.usuario.jobTitle],
                ['Telefone', protocolo.usuario.phone],
                ['Município', protocolo.usuario.municipality],
            ])}

            {renderSection('Informações de Arquitetura e da Obra', [
                ['Matrícula/Escritura', renderDocumentLink('Matrícula/Escritura', protocolo.matriculaUrl)],
                ['Programa de Necessidades', renderDocumentLink('Programa de Necessidades', protocolo.programaNecessidadesUrl)],
                ['Planta Topográfica', renderDocumentLink('Planta Topográfica', protocolo.plantaTopograficaUrl)],
                ['Relatório Fotográfico', renderDocumentLink('Relatório Fotográfico', protocolo.relatorioFotograficoUrl)],
                ['Origem do Recurso', protocolo.origemRecurso],
                ['Valor em R$', protocolo.valor],
                ['Endereço da Obra', protocolo.enderecoObra],
                ['Nomenclatura do Objeto', protocolo.nomenclaturaObjeto],
            ].filter(Boolean))}

            {renderSection('Informações Hidrossanitárias', [
                ['Existe rede pública de esgoto?', protocolo.esgoto],
                protocolo.esgoto === 'Sim' && ['Planta de Esgoto', renderDocumentLink('Planta de Esgoto', protocolo.plantaEsgotoUrl)],
                protocolo.esgoto === 'Não' && ['Teste de Infiltração', renderDocumentLink('Teste de Infiltração', protocolo.infiltracaoUrl)],
                ['Existe rede pública de distribuição de água potável?', protocolo.aguaPotavel],
                protocolo.aguaPotavel === 'Sim' && ['Planta de Água', renderDocumentLink('Planta de Água', protocolo.plantaAguaUrl)],
                protocolo.aguaPotavel === 'Não' && ['Fonte de Alimentação de Água', protocolo.fonteAlimentacao],
                protocolo.aguaPotavel === 'Não' && ['Declaração', renderDocumentLink('Declaração', protocolo.declaracaoUrl)],
            ].filter(Boolean))}

            {renderSection('Informações Estruturais', [
                ['Ensaio de Sondagem do terreno (SPT)', renderDocumentLink('Ensaio de Sondagem', protocolo.ensaioSondagemUrl)],
            ])}

            {renderSection('Informações de Orçamento', [
                ['Declaração de ISSQN', renderDocumentLink('Declaração de ISSQN', protocolo.issqnUrl)],
            ])}
        </div>
    );
};

export default AdminConstrucaoNovaView;
