// Path: sgp/src/components/data.ts

export const slides1 = [
    {
        image: '../assets/images/slider/1.jpg',
        slideTitle: 'Slide 1 Title',
        description: 'Slide 1 Description'
    },
    {
        image: '../assets/images/slider/3.jpg',
        slideTitle: 'Slide 2 Title',
        description: 'Slide 2 Description'
    },
    // Add more slides as needed
];
