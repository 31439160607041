import React, { useState } from 'react';
import { Card, Row, Col, Modal, Button } from 'react-bootstrap';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';
import { FaLeaf, FaTree } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import AmbientalCivilForm from '../components/AmbientalCivilForm';
import AmbientalInfraForm from '../components/AmbientalInfraForm'; // Importação do formulário de Infraestrutura

const SelectAmbientalType = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const navigate = useNavigate();

    const handleSelectType = (type) => {
        setModalType(type);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalType('');
    };

    return (
        <>
            <Navbar1 />
            <div className="container mt-5">
                <h2>Criar Solicitação Ambiental</h2>
                <p>Selecione o tipo de solicitação ambiental:</p>
                <Row>
                    <Col md={6} className="mb-4">
                        <Card onClick={() => handleSelectType('civil')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">CIVIL</Card.Title>
                                <Card.Text>
                                    Projetos ambientais para categorias civis como agroindústria, aeroporto, cemitério, etc.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaLeaf className="mr-2" />Agroindústria</li>
                                    <li><FaLeaf className="mr-2" />Aeroporto</li>
                                    <li><FaLeaf className="mr-2" />Cemitério</li>
                                    <li><FaLeaf className="mr-2" />Construção Nova, Reforma e Ampliação</li>
                                    <li><FaLeaf className="mr-2" />Estação de Transbordo</li>
                                    <li><FaLeaf className="mr-2" />Loteamento</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} className="mb-4">
                        <Card onClick={() => handleSelectType('infraestrutura')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">INFRAESTRUTURA</Card.Title>
                                <Card.Text>
                                    Projetos ambientais para categorias de infraestrutura como bota-fora, captação superficial, etc.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaTree className="mr-2" />Bota-fora</li>
                                    <li><FaTree className="mr-2" />Captação Superficial</li>
                                    <li><FaTree className="mr-2" />Sistema de Esgotamento Sanitário</li>
                                    <li><FaTree className="mr-2" />Estação de Transbordo</li>
                                    <li><FaTree className="mr-2" />Loteamento</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Button variant="secondary" onClick={() => navigate(-1)} className="mb-4">
                    Voltar
                </Button>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Criar Nova Requisição - {modalType === 'civil' ? 'Ambiental - Civil' : 'Ambiental - Infraestrutura'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalType === 'civil' ? <AmbientalCivilForm /> : <AmbientalInfraForm />} {/* Condicional para renderizar o formulário correto */}
                </Modal.Body>
            </Modal>

            <Footer1 />
        </>
    );
};

export default SelectAmbientalType;
