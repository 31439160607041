import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import axios from '../axiosConfig';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';
import ConstrucaoNovaView from './tipos/ConstrucaoNovaView';
import ReformaAmpliacaoView from './tipos/ReformaAmpliacaoView';
import PavimentacaoUrbanaView from './tipos/PavimentacaoUrbanaView';
import DrenagemUrbanaView from './tipos/DrenagemUrbanaView';
import EstudoHidrologicoView from './tipos/EstudoHidrologicoView';
import LevantamentoTopograficoView from './tipos/LevantamentoTopograficoView';
import EstradaVicinalView from './tipos/EstradaVicinalView';
import ObraDeArteEspecialView from './tipos/ObraDeArteEspecialView';
import AmbientalCivilView from './tipos/AmbientalCivilView';
import AmbientalInfraView from './tipos/AmbientalInfraView';
import SaneamentoView from './tipos/SaneamentoView'; // Importação do componente SaneamentoView

const ProtocoloView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [protocolo, setProtocolo] = useState(null);

    const fetchProtocolo = useCallback(async () => {
        try {
            let protocoloData = null;
            try {
                const protocoloResponse = await axios.get(`/api/protocolos/${id}`);
                protocoloData = protocoloResponse.data;
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    try {
                        const pavimentacaoResponse = await axios.get(`/api/pavimentacao-urbana/${id}`);
                        protocoloData = pavimentacaoResponse.data;
                    } catch (error) {
                        if (error.response && error.response.status === 404) {
                            try {
                                const drenagemResponse = await axios.get(`/api/drenagem-urbana/${id}`);
                                protocoloData = drenagemResponse.data;
                            } catch (error) {
                                if (error.response && error.response.status === 404) {
                                    try {
                                        const estudoHidrologicoResponse = await axios.get(`/api/estudo-hidrologico/${id}`);
                                        protocoloData = estudoHidrologicoResponse.data;
                                    } catch (error) {
                                        if (error.response && error.response.status === 404) {
                                            try {
                                                const levantamentoTopograficoResponse = await axios.get(`/api/levantamento-topografico/${id}`);
                                                protocoloData = levantamentoTopograficoResponse.data;
                                            } catch (error) {
                                                if (error.response && error.response.status === 404) {
                                                    try {
                                                        const estradaVicinalResponse = await axios.get(`/api/estrada-vicinal/${id}`);
                                                        protocoloData = estradaVicinalResponse.data;
                                                    } catch (error) {
                                                        if (error.response && error.response.status === 404) {
                                                            try {
                                                                const obraDeArteEspecialResponse = await axios.get(`/api/obra-de-arte-especial/${id}`);
                                                                protocoloData = obraDeArteEspecialResponse.data;
                                                            } catch (error) {
                                                                if (error.response && error.response.status === 404) {
                                                                    try {
                                                                        const ambientalCivilResponse = await axios.get(`/api/ambiental-civil/${id}`);
                                                                        protocoloData = ambientalCivilResponse.data;
                                                                    } catch (error) {
                                                                        if (error.response && error.response.status === 404) {
                                                                            try {
                                                                                const ambientalInfraResponse = await axios.get(`/api/ambiental-infra/${id}`);
                                                                                protocoloData = ambientalInfraResponse.data;
                                                                            } catch (error) {
                                                                                if (error.response && error.response.status === 404) {
                                                                                    try {
                                                                                        const saneamentoResponse = await axios.get(`/api/saneamento/${id}`);
                                                                                        protocoloData = saneamentoResponse.data;
                                                                                    } catch (error) {
                                                                                        if (error.response && error.response.status === 404) {
                                                                                            console.error('Nenhum protocolo encontrado com o ID fornecido');
                                                                                        } else {
                                                                                            throw error;
                                                                                        }
                                                                                    }
                                                                                } else {
                                                                                    throw error;
                                                                                }
                                                                            }
                                                                        } else {
                                                                            throw error;
                                                                        }
                                                                    }
                                                                } else {
                                                                    throw error;
                                                                }
                                                            }
                                                        } else {
                                                            throw error;
                                                        }
                                                    }
                                                } else {
                                                    throw error;
                                                }
                                            }
                                        } else {
                                            throw error;
                                        }
                                    }
                                } else {
                                    throw error;
                                }
                            }
                        } else {
                            throw error;
                        }
                    }
                } else {
                    throw error;
                }
            }
    
            if (protocoloData) {
                setProtocolo(protocoloData);
            } else {
                console.error('Nenhum protocolo encontrado com o ID fornecido');
            }
        } catch (error) {
            console.error('Erro ao buscar protocolo:', error);
        }
    }, [id]);       

    useEffect(() => {
        fetchProtocolo();
    }, [fetchProtocolo]);

    if (!protocolo) {
        return <h2>Carregando protocolo...</h2>;
    }

    const renderProtocoloView = () => {
        switch (protocolo.tipo) {
            case 'Construção nova':
                return <ConstrucaoNovaView protocolo={protocolo} />;
            case 'Reforma e Ampliação':
                return <ReformaAmpliacaoView protocolo={protocolo} />;
            case 'Pavimentação Urbana':
                return <PavimentacaoUrbanaView protocolo={protocolo} />;
            case 'Drenagem Urbana':
                return <DrenagemUrbanaView protocolo={protocolo} />;
            case 'Estudo Hidrológico':
                return <EstudoHidrologicoView protocolo={protocolo} />;
            case 'Levantamento Topográfico': 
                return <LevantamentoTopograficoView protocolo={protocolo} />;
            case 'Estrada Vicinal':
                return <EstradaVicinalView protocolo={protocolo} />;
            case 'Obra de Arte Especial':
                return <ObraDeArteEspecialView protocolo={protocolo} />;
            case 'Ambiental Civil':
                return <AmbientalCivilView protocolo={protocolo} />;
            case 'Ambiental Infra':
            case 'Ambiental Infraestrutura':
                return <AmbientalInfraView protocolo={protocolo} />;
            case 'Saneamento':
                return <SaneamentoView protocolo={protocolo} />;
            default:
                return <h2>Tipo de protocolo não reconhecido</h2>;
        }
    };

    return (
        <>
            <Navbar1 />
            <div className="container mt-5">
                {renderProtocoloView()}
                <Button variant="secondary" onClick={() => navigate(-1)} className="mt-4">
                    Voltar
                </Button>
            </div>
            <Footer1 />
        </>
    );
};

export default ProtocoloView;
