import React, { useState, useEffect } from 'react';
import { Table, Card, Button, Badge } from 'react-bootstrap';
import PendenciasModal from '../../components/PendenciasModalAmbInfra';
import axios from '../../axiosConfig';

const AdminAmbientalInfraView = ({ protocolo }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedField, setSelectedField] = useState('');
    const [issues, setIssues] = useState(protocolo.pendingIssues || []);

    useEffect(() => {
        setIssues(protocolo.pendingIssues || []);
    }, [protocolo]);

    const handleIssueAdded = async () => {
        try {
            const response = await axios.get(`/api/ambiental-infra/${protocolo._id}`);
            setIssues(response.data.pendingIssues);
        } catch (error) {
            console.error('Erro ao atualizar as pendências:', error);
        }
    };

    const renderStatusTag = (status) => {
        switch (status) {
            case 'pendente':
                return <Badge bg="warning">Pendente</Badge>;
            case 'respondido':
                return <Badge bg="success">Respondido</Badge>;
            case 'aprovado':
                return <Badge bg="primary">Aprovado</Badge>;
            default:
                return null;
        }
    };

    const renderPendingIssues = (fieldName) => {
        const fieldIssues = issues.filter(issue => issue.fieldName === fieldName);
        return fieldIssues.length > 0 ? (
            <ul>
                {fieldIssues.map((issue, index) => (
                    <li key={index} style={{ marginBottom: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            {issue.description} {renderStatusTag(issue.status)}
                            {issue.status === 'pendente'}
                        </div>
                        {issue.status === 'respondido' && issue.response && (
                            <div style={{ marginLeft: '20px', marginTop: '5px', color: '#28a745' }}>
                                <strong>Resposta:</strong> {issue.response}
                                <br />
                                <small>Respondido em: {new Date(issue.responseDate).toLocaleString()}</small>
                            </div>
                        )}
                        {issue.status === 'respondido' && issue.responseFileUrl && (
                            <div style={{ marginLeft: '20px', marginTop: '5px' }}>
                                <strong>Arquivo enviado:</strong> <a href={issue.responseFileUrl} target="_blank" rel="noopener noreferrer">{issue.responseFileName || 'Visualizar arquivo'}</a>
                                <br />
                                <small>Respondido em: {new Date(issue.responseDate).toLocaleString()}</small>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        ) : '';
    };

    const renderDocumentLink = (label, url) => (
        url ? <a href={url} target="_blank" rel="noopener noreferrer">Visualizar documento</a> : 'Arquivo Não informado'
    );

    const renderSection = (title, fields) => (
        <div className="mb-4">
            <h5 className="section-title">{title}</h5>
            <Table striped bordered hover className="custom-table">
                <thead>
                    <tr>
                        <th style={{ width: '450px' }}>Campo</th>
                        <th>Valor</th>
                        <th style={{ width: '550px' }}>Pendências</th>
                        <th style={{ width: '100px' }}>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map(([label, value], index) => (
                        <tr key={index}>
                            <td><strong>{label}</strong></td>
                            <td>{value || 'Não informado'}</td>
                            <td>{renderPendingIssues(label)}</td>
                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    onClick={() => {
                                        setSelectedField(label);
                                        setShowModal(true);
                                    }}
                                    variant="danger"
                                    size="sm"
                                    style={{ padding: '2px 6px', fontSize: '12px'}}
                                >
                                    Add Pend.
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );

    const getStatusColor = (status) => {
        switch (status) {
            case 'aprovado':
                return '#28a745';
            case 'pendente':
                return '#ffc107';
            case 'rejeitado':
                return '#dc3545';
            default:
                return '#6c757d';
        }
    };

    return (
        <div>
            <PendenciasModal
                protocoloId={protocolo._id}
                fieldName={selectedField}
                show={showModal}
                onHide={() => setShowModal(false)}
                onIssueAdded={handleIssueAdded}
            />
            <Card className="mb-4" style={{ backgroundColor: '#007bff', color: 'white' }}>
                <Card.Body>
                    <h2 className="texto">
                        {protocolo.nomeObjeto} - {protocolo.usuario.municipality}
                    </h2>
                </Card.Body>
            </Card>

            <Card className="mb-4" style={{ backgroundColor: getStatusColor(protocolo.status), color: 'white', height: '50px', lineHeight: '30px', padding: '10px 20px' }}>
                <Card.Body className="p-0 d-flex align-items-center">
                    <h4 className="m-0">
                        Status: {protocolo.status.charAt(0).toUpperCase() + protocolo.status.slice(1)}
                    </h4>
                </Card.Body>
            </Card>

            {protocolo.status === 'rejeitado' && protocolo.rejectionJustification && (
                <Card className="mb-4" style={{ backgroundColor: '#dc3545', color: 'white' }}>
                    <Card.Body>
                        <h4>Justificativa da Rejeição</h4>
                        <p>{protocolo.rejectionJustification}</p>
                    </Card.Body>
                </Card>
            )}

            <h2>Detalhes do Protocolo - Ambiental Infraestrutura</h2>

            {renderSection('Dados Gerais', [
                ['Número do Protocolo', protocolo.numeroProtocolo],
                ['Nome da Solicitação', protocolo.nomeObjeto],
                ['Tipo da Solicitação', protocolo.subtipoProjeto],
                ['Ofício', renderDocumentLink('Ofício', protocolo.oficioSolicitacaoUrl)],
                ['Data de Criação', new Date(protocolo.createdAt).toLocaleString()],
                ['Data de Atualização', new Date(protocolo.updatedAt).toLocaleString()],
            ])}

            {renderSection('Dados do Solicitante', [
                ['Nome Completo', protocolo.usuario.fullName],
                ['Email', protocolo.usuario.email],
                ['Cargo', protocolo.usuario.jobTitle],
                ['Telefone', protocolo.usuario.phone],
                ['Município', protocolo.usuario.municipality],
            ])}

            {/* Exibe as seções com base no subtipo do protocolo */}
            {protocolo.subtipoProjeto === 'botaFora' && renderSection('Bota-fora', [
                ['Documentos do Prefeito', renderDocumentLink('Documentos do Prefeito', protocolo.documentosPrefeitoUrl)],
                ['Área Proposta', renderDocumentLink('Área Proposta', protocolo.areaPropostaUrl)],
                ['Relatório Fotográfico', renderDocumentLink('Relatório Fotográfico', protocolo.relatorioFotograficoUrl)],
                ['Documento da Área', renderDocumentLink('Documento da Área', protocolo.documentoAreaUrl)],
                ['Imagem do Polígono da Área', renderDocumentLink('Imagem do Polígono da Área', protocolo.imagemPoligonoUrl)],
                ['Procuração do Prefeito', renderDocumentLink('Procuração do Prefeito', protocolo.procuracaoPrefeitoUrl)],
                ['Declaração de Uso e Ocupação do Solo', renderDocumentLink('Declaração de Uso e Ocupação do Solo', protocolo.declaracaoUsoSoloUrl)],
                ['Pagamento da ART', renderDocumentLink('Pagamento da ART', protocolo.pagamentoArtUrl)],
                ['Comprovante de Ligação de Água', renderDocumentLink('Comprovante de Ligação de Água', protocolo.comprovanteAguaUrl)],
                ['ART do Engenheiro', renderDocumentLink('ART do Engenheiro', protocolo.artEngenheiroUrl)],
            ])}

            {protocolo.subtipoProjeto === 'captacaoSuperficial' && renderSection('Captação Superficial', [
                ['Identificação do Corpo Hídrico', protocolo.identificacaoCorpoHidrico],
                ['Coordenadas do Ponto de Captação', protocolo.coordenadasPontoCaptacao],
                ['Identificação do Sistema de Captação', protocolo.identificacaoSistemaCaptacao],
                ['Dados Técnicos da Captação', protocolo.dadosTecnicosCaptacao],
                ['Procuração do Prefeito', renderDocumentLink('Procuração do Prefeito', protocolo.procuracaoPrefeitoUrl)],
                ['Documentos do Prefeito', renderDocumentLink('Documentos do Prefeito', protocolo.documentosPrefeitoUrl)],
                ['Publicação do Pedido de Outorga', renderDocumentLink('Publicação do Pedido de Outorga', protocolo.publicacaoOutorgaUrl)],
                ['Declaração de Uso e Ocupação do Solo', renderDocumentLink('Declaração de Uso e Ocupação do Solo', protocolo.declaracaoUsoSoloUrl)],
            ])}

            {protocolo.subtipoProjeto === 'sistemaEsgotamentoSanitario' && renderSection('Sistema de Esgotamento Sanitário', [
                ['Identificação do Corpo Hídrico Receptor', protocolo.identificacaoCorpoHidricoReceptor],
                ['Coordenadas do Ponto de Lançamento', protocolo.coordenadasPontoLancamento],
                ['Dados Técnicos do Sistema', protocolo.dadosTecnicosSistema],
                ['Fluxograma do Sistema de Tratamento', renderDocumentLink('Fluxograma do Sistema de Tratamento', protocolo.fluxogramaSistemaTratamentoUrl)],
                ['Boletim de Análise do Efluente', renderDocumentLink('Boletim de Análise do Efluente', protocolo.boletimAnaliseEfluenteUrl)],
                ['Procuração do Prefeito', renderDocumentLink('Procuração do Prefeito', protocolo.procuracaoPrefeitoUrl)],
                ['Documentos do Prefeito', renderDocumentLink('Documentos do Prefeito', protocolo.documentosPrefeitoUrl)],
                ['Declaração de Uso e Ocupação do Solo', renderDocumentLink('Declaração de Uso e Ocupação do Solo', protocolo.declaracaoUsoSoloUrl)],
            ])}
        </div>
    );
};

export default AdminAmbientalInfraView;
