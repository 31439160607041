import React, { useState } from 'react';
import { Table, Card, Button, Badge, Form } from 'react-bootstrap';
import axios from '../../axiosConfig';

const ConstrucaoNovaView = ({ protocolo }) => {
    const [responses, setResponses] = useState({});
    const [fileResponses, setFileResponses] = useState({});
    const [protocoloData, setProtocoloData] = useState(protocolo); // Corrigido aqui

    const handleResponseChange = (issueId, value) => {
        setResponses({
            ...responses,
            [issueId]: value,
        });
    };

    const handleFileChange = (issueId, file) => {
        setFileResponses({
            ...fileResponses,
            [issueId]: file,
        });
    };

    const handleResponseSubmit = async (issueId) => {
        try {
            const response = responses[issueId];
            const fileResponse = fileResponses[issueId];

            let updatedIssue;

            if (fileResponse) {
                const formData = new FormData();
                formData.append('file', fileResponse);

                const result = await axios.post(`/api/protocolos/${protocolo._id}/pendencias/${issueId}/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                updatedIssue = result.data.protocolo.pendingIssues.find(issue => issue._id === issueId);
            } else if (response) {
                const result = await axios.put(`/api/protocolos/${protocolo._id}/pendencias/${issueId}/responder`, {
                    response,
                });
                updatedIssue = result.data.protocolo.pendingIssues.find(issue => issue._id === issueId);
            }

            // Atualiza a pendência no estado local do protocolo
            if (updatedIssue) {
                setProtocoloData(prevState => {
                    const updatedPendingIssues = prevState.pendingIssues.map(issue =>
                        issue._id === issueId ? updatedIssue : issue
                    );
                    return { ...prevState, pendingIssues: updatedPendingIssues };
                });
            }

            // Limpa as respostas locais após o envio
            setResponses(prev => ({ ...prev, [issueId]: '' }));
            setFileResponses(prev => ({ ...prev, [issueId]: null }));
        } catch (error) {
            console.error('Erro ao enviar resposta:', error);
        }
    };

    const renderStatusTag = (status) => {
        switch (status) {
            case 'pendente':
                return <Badge bg="warning">Pendente</Badge>;
            case 'respondido':
                return <Badge bg="success">respondido</Badge>;
            case 'aprovado':
                return <Badge bg="primary">Aprovado</Badge>;
            default:
                return null;
        }
    };

    const renderPendingIssues = (fieldName, fieldValue) => {
        const issues = protocoloData.pendingIssues?.filter(issue => issue.fieldName === fieldName);
    
        // Verifica se o fieldValue é um elemento React do tipo <a> ou se é uma string com o texto "Arquivo Não informado"
        const isFileUpload = 
            (React.isValidElement(fieldValue) && fieldValue.type === 'a') || 
            (typeof fieldValue === 'string' && fieldValue === 'Arquivo Não informado');
    
        return issues && issues.length > 0 ? (
            <ul>
                {issues.map((issue, index) => (
                    <li key={index}>
                        {issue.description} {renderStatusTag(issue.status)}
                        {issue.status === 'pendente' && (
                            <div style={{ marginTop: '10px' }}>
                                {isFileUpload ? (
                                    <div>
                                        <Form.Control
                                            type="file"
                                            onChange={(e) => handleFileChange(issue._id, e.target.files[0])}
                                            style={{ marginTop: '10px' }}
                                        />
                                    </div>
                                ) : (
                                    <Form.Control
                                        type="text"
                                        placeholder="Responda aqui..."
                                        value={responses[issue._id] || ''}
                                        onChange={(e) => handleResponseChange(issue._id, e.target.value)}
                                    />
                                )}
                                <Button
                                    onClick={() => handleResponseSubmit(issue._id)}
                                    variant="primary"
                                    size="sm"
                                    style={{ marginTop: '5px' }}
                                >
                                    Enviar Resposta
                                </Button>
                            </div>
                        )}
                        {issue.status === 'respondido' && issue.response && (
                            <div style={{ marginLeft: '20px', marginTop: '5px', color: '#28a745' }}>
                                <strong>Resposta:</strong> {issue.response}
                                <br />
                                <small>Respondido em: {new Date(issue.responseDate).toLocaleString()}</small>
                            </div>
                        )}
                        {issue.status === 'respondido' && issue.responseFileUrl && (
                            <div style={{ marginLeft: '20px', marginTop: '5px' }}>
                                <strong>Arquivo enviado:</strong> <a href={issue.responseFileUrl} target="_blank" rel="noopener noreferrer">{issue.responseFileName || 'Visualizar arquivo'}</a>
                                <br />
                                <small>Respondido em: {new Date(issue.responseDate).toLocaleString()}</small>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        ) : '';
    };   

    const renderDocumentLink = (label, url) => (
        url ? <a href={url} target="_blank" rel="noopener noreferrer">Visualizar documento</a> : 'Arquivo Não informado'
    );

    const renderSection = (title, fields) => (
        <div className="mb-4">
            <h5 className="section-title">{title}</h5>
            <Table striped bordered hover className="custom-table">
                <thead>
                    <tr>
                        <th style={{ width: '450px' }}>Campo</th>
                        <th>Valor</th>
                        <th style={{ width: '400px' }}>Pendências</th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map(([label, value], index) => (
                        <tr key={index}>
                            <td><strong>{label}</strong></td>
                            <td>{value || 'Não informado'}</td>
                            <td>{renderPendingIssues(label, value)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );

    const getStatusColor = (status) => {
        switch (status) {
            case 'aprovado':
                return '#28a745'; // Verde
            case 'pendente':
                return '#ffc107'; // Amarelo
            case 'rejeitado':
                return '#dc3545'; // Vermelho
            default:
                return '#6c757d'; // Cinza (para status desconhecidos)
        }
    };

    return (
        <div>
            <Card className="mb-4" style={{ backgroundColor: '#007bff', color: 'white' }}>
                <Card.Body>
                    <h2 className="texto">
                        {protocolo.nomeObjeto} - {protocolo.usuario.municipality}
                    </h2>
                </Card.Body>
            </Card>

            <Card className="mb-4" style={{ backgroundColor: getStatusColor(protocolo.status), color: 'white', height: '50px', lineHeight: '30px', padding: '10px 20px' }}>
                <Card.Body className="p-0 d-flex align-items-center">
                    <h4 className="m-0">
                        Status: {protocolo.status.charAt(0).toUpperCase() + protocolo.status.slice(1)}
                    </h4>
                </Card.Body>
            </Card>
            
            {protocolo.status === 'rejeitado' && protocolo.rejectionJustification && (
                <Card className="mb-4" style={{ backgroundColor: '#dc3545', color: 'white' }}>
                    <Card.Body>
                        <h4>Justificativa da Rejeição</h4>
                        <p>{protocolo.rejectionJustification}</p>
                    </Card.Body>
                </Card>
            )}

            <h2>Detalhes do Protocolo - Construção Nova</h2>
            
            {renderSection('Dados Gerais', [
                ['Número do Protocolo', protocolo.numeroProtocolo],
                ['Nome da Solicitação', protocolo.nomeObjeto],
                ['Tipo de Obra', protocolo.tipo],
                ['Ofício', renderDocumentLink('Ofício', protocolo.oficioUrl)],
                ['Data de Criação', new Date(protocolo.createdAt).toLocaleString()],
                ['Data de Atualização', new Date(protocolo.updatedAt).toLocaleString()],
            ].filter(Boolean))}

            {renderSection('Dados do Solicitante', [
                ['Nome Completo', protocolo.usuario.fullName],
                ['Email', protocolo.usuario.email],
                ['Cargo', protocolo.usuario.jobTitle],
                ['Telefone', protocolo.usuario.phone],
                ['Município', protocolo.usuario.municipality],
            ].filter(Boolean))}

            {renderSection('Informações de Arquitetura e da Obra', [
                ['Matrícula/Escritura', renderDocumentLink('Matrícula/Escritura', protocolo.matriculaUrl)],
                ['Programa de Necessidades', renderDocumentLink('Programa de Necessidades', protocolo.programaNecessidadesUrl)],
                ['Planta Topográfica', renderDocumentLink('Planta Topográfica', protocolo.plantaTopograficaUrl)],
                ['Relatório Fotográfico', renderDocumentLink('Relatório Fotográfico', protocolo.relatorioFotograficoUrl)],
                ['Origem do Recurso', protocolo.origemRecurso],
                ['Valor em R$', protocolo.valor],
                ['Endereço da Obra', protocolo.enderecoObra],
                ['Nomenclatura do Objeto', protocolo.nomenclaturaObjeto],
            ].filter(Boolean))}

            {renderSection('Informações Hidrossanitárias', [
                ['Existe rede pública de esgoto?', protocolo.esgoto],
                protocolo.esgoto === 'Sim' && ['Planta de Esgoto', renderDocumentLink('Planta de Esgoto', protocolo.plantaEsgotoUrl)],
                protocolo.esgoto === 'Não' && ['Teste de Infiltração', renderDocumentLink('Teste de Infiltração', protocolo.infiltracaoUrl)],
                ['Existe rede pública de distribuição de água potável?', protocolo.aguaPotavel],
                protocolo.aguaPotavel === 'Sim' && ['Planta de Água', renderDocumentLink('Planta de Água', protocolo.plantaAguaUrl)],
                protocolo.aguaPotavel === 'Não' && ['Fonte de Alimentação de Água', protocolo.fonteAlimentacao],
                protocolo.aguaPotavel === 'Não' && ['Declaração', renderDocumentLink('Declaração', protocolo.declaracaoUrl)],
            ].filter(Boolean))}

            {renderSection('Informações Estruturais', [
                ['Ensaio de Sondagem do terreno (SPT)', renderDocumentLink('Ensaio de Sondagem', protocolo.ensaioSondagemUrl)],
            ].filter(Boolean))}

            {renderSection('Informações de Orçamento', [
                ['Declaração de ISSQN', renderDocumentLink('Declaração de ISSQN', protocolo.issqnUrl)],
            ].filter(Boolean))}
        </div>
    );
};

export default ConstrucaoNovaView;
