// Path: src/components/PavimentacaoUrbanaForm.js
import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';

const PavimentacaoUrbanaForm = ({ onSuccess }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        nomeObjeto: '',
        oficio: null,
        plantaMunicipio: null,
        indicacaoRuas: null,
        plantaTopografica: null,
        larguraVias: '',
        origemRecurso: '',
        extratoProposta: null,
        ensaiosSubleito: null,
        calcada: 'Não',
        larguraCalcada: '',
        todasViasCalcada: 'Não',
        ensaiosJazida: null,
        licencaJazida: null,
        localizacaoJazida: '',
        licencaBotaFora: null,
        localizacaoBotaFora: '',
        relatorioFotografico: null,
        issqn: null
    });

    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const data = new FormData();
            for (const key in formData) {
                if (formData[key] !== null && formData[key] !== '') {
                    data.append(key, formData[key]);
                }
            }
    
            const response = await axios.post('/api/protocolos/pavimentacao-urbana', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data && response.data.protocolo && response.data.protocolo._id) {
                const createdProtocoloId = response.data.protocolo._id;
                navigate(`/protocolo/${createdProtocoloId}`);
            } else {
                throw new Error('ID do protocolo não foi retornado');
            }
        } catch (error) {
            console.error('Erro ao criar protocolo:', error);
            setError('Erro ao criar protocolo. Por favor, tente novamente.');
        }
    };    

    const renderCivilStep = () => (
        <>
            <h4>Dados Gerais</h4>
            <Form.Group className="mb-3">
                <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
                <Form.Control
                    type="text"
                    name="nomeObjeto"
                    value={formData.nomeObjeto}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="oficio"
                    onChange={handleChange}
                    required
                />
            </Form.Group>
        </>
    );

    const renderArquiteturaStep = () => (
        <>
            <h4>Informações da Pavimentação Urbana</h4>
            <Form.Group className="mb-3">
                <Form.Label>Planta do Município (DWG)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaMunicipio"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Indicação das Ruas (KMZ)</Form.Label>
                <Form.Control
                    type="file"
                    name="indicacaoRuas"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Planta Topográfica (se não for feito pela AMM)</Form.Label>
                <Form.Control
                    type="file"
                    name="plantaTopografica"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Largura das Vias</Form.Label>
                <Form.Control
                    type="text"
                    name="larguraVias"
                    value={formData.larguraVias}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Origem do Recurso</Form.Label>
                <Form.Control
                    type="text"
                    name="origemRecurso"
                    value={formData.origemRecurso}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Extrato da Proposta (se aplicável)</Form.Label>
                <Form.Control
                    type="file"
                    name="extratoProposta"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Ensaios Subleito</Form.Label>
                <Form.Control
                    type="file"
                    name="ensaiosSubleito"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Vai ter Calçada?</Form.Label>
                <Form.Control
                    as="select"
                    name="calcada"
                    value={formData.calcada}
                    onChange={handleChange}
                >
                    <option>Sim</option>
                    <option>Não</option>
                </Form.Control>
            </Form.Group>
            {formData.calcada === 'Sim' && (
                <>
                    <Form.Group className="mb-3">
                        <Form.Label>Especificar Largura da Calçada</Form.Label>
                        <Form.Control
                            type="text"
                            name="larguraCalcada"
                            value={formData.larguraCalcada}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Todas as Vias terão Calçada?</Form.Label>
                        <Form.Control
                            as="select"
                            name="todasViasCalcada"
                            value={formData.todasViasCalcada}
                            onChange={handleChange}
                        >
                            <option>Sim</option>
                            <option>Não</option>
                        </Form.Control>
                    </Form.Group>
                </>
            )}
        </>
    );

    const renderJazidaBotaForaStep = () => (
        <>
            <h4>Jazida e Bota-Fora</h4>
            <Form.Group className="mb-3">
                <Form.Label>Ensaios da Jazida</Form.Label>
                <Form.Control
                    type="file"
                    name="ensaiosJazida"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Licença da Jazida</Form.Label>
                <Form.Control
                    type="file"
                    name="licencaJazida"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Localização da Jazida (GMS)</Form.Label>
                <Form.Control
                    type="text"
                    name="localizacaoJazida"
                    value={formData.localizacaoJazida}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Licença ou Declaração de Bota-Fora</Form.Label>
                <Form.Control
                    type="file"
                    name="licencaBotaFora"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Localização do Bota-Fora (GMS)</Form.Label>
                <Form.Control
                    type="text"
                    name="localizacaoBotaFora"
                    value={formData.localizacaoBotaFora}
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    const renderOrcamentoStep = () => (
        <>
            <h4>Informações de Orçamento</h4>
            <Form.Group className="mb-3">
                <Form.Label>Relatório Fotográfico</Form.Label>
                <Form.Control
                    type="file"
                    name="relatorioFotografico"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Declaração de ISSQN com alíquota e base de cálculo</Form.Label>
                <Form.Control
                    type="file"
                    name="issqn"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            {step === 1 && renderCivilStep()}
            {step === 2 && renderArquiteturaStep()}
            {step === 3 && renderJazidaBotaForaStep()}
            {step === 4 && renderOrcamentoStep()}

            <div className="d-flex justify-content-between mt-4">
                {step > 1 && (
                    <Button variant="secondary" onClick={handlePrevious}>
                        Voltar
                    </Button>
                )}
                {step < 4 && (
                    <Button variant="primary" onClick={handleNext}>
                        Próxima Fase
                    </Button>
                )}
                {step === 4 && (
                    <Button variant="primary" type="submit">
                        Enviar Solicitação
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default PavimentacaoUrbanaForm;
