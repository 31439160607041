import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaFolder, FaBell, FaUserCheck, FaSlidersH, FaCog } from 'react-icons/fa'; 
import logoCentral from '../assets/images/logo.png'; // Presumindo que o logo da Central de Projetos está armazenado localmente
import AuthContext from '../context/AuthContext';

const Navbar1 = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const getFirstName = () => {
        if (user && user.fullName) {
            return user.fullName.split(' ')[0];
        }
        return 'User';
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container d-flex justify-content-between align-items-center">
                <Link className="navbar-brand d-flex align-items-center" to="/">
                    <img src={logoCentral} alt="logo Central de Projetos" height="55" className="me-2" />
                    <img src="https://www.amm.org.br/images/brasao_padrao.svg" alt="logo AMM" height="55" className="me-2" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link className="nav-link" aria-current="page" to="/"><FaHome className="me-1" />Home</Link>
                        </li>

                        {user && user.role === 'admin' && (
                            <>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/user-management"><FaUserCheck className="me-1" />Usuários</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/slider-config"><FaSlidersH className="me-1" />Slider</Link>
                                </li>
                            </>
                        )}
                        <li className="nav-item">
                            <Link className="nav-link" to="https://www.amm.org.br/"><FaFolder className="me-1" />Site AMM</Link>
                        </li>
                    </ul>
                    
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/manage-user"><FaCog /></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/notifications"><FaBell /></Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="#" onClick={handleLogout}>
                                {getFirstName()} <span className="text-muted ms-1">(Logout)</span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};


export default Navbar1;
