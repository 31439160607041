import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';

const LevantamentoTopograficoForm = ({ onSuccess }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        nomeObjeto: '',
        oficio: null,
        locacaoAreaKmz: null,
        relatorioCaracterizacao: null,
    });

    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: files ? files[0] : value,
        });
    };

    const handleNext = () => {
        setStep(step + 1);
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
            const data = new FormData();
            for (const key in formData) {
                if (formData[key] !== null && formData[key] !== '') {
                    data.append(key, formData[key]);
                }
            }
    
            const response = await axios.post('/api/levantamento-topografico', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
    
            if (response.data && response.data.protocolo && response.data.protocolo._id) {
                const createdProtocoloId = response.data.protocolo._id;
                navigate(`/protocolo/${createdProtocoloId}`);
            } else {
                throw new Error('ID do protocolo não foi retornado');
            }
        } catch (error) {
            console.error('Erro ao criar protocolo:', error);
            setError('Erro ao criar protocolo. Por favor, tente novamente.');
        }
    };

    const renderCivilStep = () => (
        <>
            <h4>Dados Gerais</h4>
            <Form.Group className="mb-3">
                <Form.Label>Nome da Solicitação (Objeto)</Form.Label>
                <Form.Control
                    type="text"
                    name="nomeObjeto"
                    value={formData.nomeObjeto}
                    onChange={handleChange}
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Ofício da Solicitação (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="oficio"
                    onChange={handleChange}
                    required
                />
            </Form.Group>
        </>
    );

    const renderArquiteturaStep = () => (
        <>
            <h4>Planejamento Inicial</h4>
            <Form.Group className="mb-3">
                <Form.Label>Locação da Área a Ser Levantada (KMZ)</Form.Label>
                <Form.Control
                    type="file"
                    name="locacaoAreaKmz"
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Relatório de Caracterização do Local com Fotos (PDF)</Form.Label>
                <Form.Control
                    type="file"
                    name="relatorioCaracterizacao"
                    onChange={handleChange}
                />
            </Form.Group>
        </>
    );

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            {step === 1 && renderCivilStep()}
            {step === 2 && renderArquiteturaStep()}

            <div className="d-flex justify-content-between mt-4">
                {step > 1 && (
                    <Button variant="secondary" onClick={handlePrevious}>
                        Voltar
                    </Button>
                )}
                {step < 2 && (
                    <Button variant="primary" onClick={handleNext}>
                        Próxima Fase
                    </Button>
                )}
                {step === 2 && (
                    <Button variant="primary" type="submit">
                        Enviar Solicitação
                    </Button>
                )}
            </div>
        </Form>
    );
};

export default LevantamentoTopograficoForm;
