import React, { useState } from 'react';
import { Card, Row, Col, Modal, Button } from 'react-bootstrap';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';
import PavimentacaoUrbanaForm from '../components/PavimentacaoUrbanaForm';
import DrenagemUrbanaForm from '../components/DrenagemUrbanaForm';
import EstradasVicinaisForm from '../components/EstradaVicinalForm';
import EstudoHidrologicoForm from '../components/EstudoHidrologicoForm';
import ObraArteEspecialForm from '../components/ObraDeArteEspecialForm';
import SaneamentoForm from '../components/SaneamentoForm';
import { FaRoad, FaWater, FaHandHoldingWater, FaLandmark, FaRecycle } from 'react-icons/fa';
import { useNavigate} from 'react-router-dom';

const SelectInfrastructureType = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const navigate = useNavigate();
    

    const handleSelectType = (type) => {
        setModalType(type); // Define o tipo de modal a ser aberto
        setShowModal(true);  // Abre o modal
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModalType(''); // Reseta o tipo de modal
    };

    const renderModalContent = () => {
        switch (modalType) {
            case 'pavimentacao-urbana':
                return <PavimentacaoUrbanaForm onSuccess={handleCloseModal} />;
            case 'drenagem-urbana':
                return <DrenagemUrbanaForm onSuccess={handleCloseModal} />;
            case 'estradas-vicinais':
                return <EstradasVicinaisForm onSuccess={handleCloseModal} />;
            case 'estudo-hidrologico':
                return <EstudoHidrologicoForm onSuccess={handleCloseModal} />;
            case 'obra-arte-especial':
                return <ObraArteEspecialForm onSuccess={handleCloseModal} />;
            case 'saneamento':
                return <SaneamentoForm onSuccess={handleCloseModal} />; // Renderizar o formulário de Saneamento
            default:
                return null;
        }
    };

    const renderModalTitle = () => {
        switch (modalType) {
            case 'pavimentacao-urbana':
                return 'Pavimentação Urbana';
            case 'drenagem-urbana':
                return 'Drenagem Urbana';
            case 'estradas-vicinais':
                return 'Estradas Vicinais';
            case 'estudo-hidrologico':
                return 'Estudo Hidrológico';
            case 'obra-arte-especial':
                return 'Obra de Arte Especial';
            case 'saneamento':
                return 'Saneamento'; // Título do modal de Saneamento
            default:
                return '';
        }
    };

    return (
        <>
            <Navbar1 />
            <div className="container mt-5">
                <h2>Criar Solicitação de Infraestrutura</h2>
                <p>Selecione o tipo de solicitação de infraestrutura:</p>
                <Row>
                    <Col md={6} lg={4} className="mb-4">
                        <Card onClick={() => handleSelectType('pavimentacao-urbana')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">Pavimentação Urbana</Card.Title>
                                <Card.Text className="mb-3">
                                    Projetos de pavimentação e recuperação de vias urbanas e calçadas.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaRoad className="mr-2" /> Pavimentação de vias urbanas</li>
                                    <li><FaRoad className="mr-2" /> Recuperação de vias existentes</li>
                                    <li><FaRoad className="mr-2" /> Construção de Calçadas</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className="mb-4">
                        <Card onClick={() => handleSelectType('drenagem-urbana')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">Drenagem Urbana</Card.Title>
                                <Card.Text className="mb-3">
                                    Projetos de sistemas de drenagem urbana para controle de águas pluviais.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaWater className="mr-2" /> Drenagem superficial</li>
                                    <li><FaWater className="mr-2" /> Drenagem profunda</li>
                                    <li><FaWater className="mr-2" /> Dispositivos de drenagem</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className="mb-4">
                        <Card onClick={() => handleSelectType('estradas-vicinais')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">Estradas Vicinais</Card.Title>
                                <Card.Text className="mb-3">
                                    Projetos de abertura, recuperação e manutenção de estradas vicinais.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaRoad className="mr-2" /> Abertura de estradas</li>
                                    <li><FaRoad className="mr-2" /> Recuperação de estradas</li>
                                    <li><FaRoad className="mr-2" /> Pavimentação de estradas</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className="mb-4">
                        <Card onClick={() => handleSelectType('estudo-hidrologico')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">Estudo Hidrológico</Card.Title>
                                <Card.Text className="mb-3">
                                    Estudos e projetos relacionados ao comportamento das águas superficiais e subterrâneas.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaHandHoldingWater className="mr-2" /> Estudos de bacias hidrográficas</li>
                                    <li><FaHandHoldingWater className="mr-2" /> Mapeamento de áreas inundáveis</li>
                                    <li><FaHandHoldingWater className="mr-2" /> Estudos para execução de pontes</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className="mb-4">
                        <Card onClick={() => handleSelectType('obra-arte-especial')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">Obra de Arte Especial</Card.Title>
                                <Card.Text className="mb-3">
                                    Projetos de estruturas como pontes e viadutos, que exigem soluções técnicas específicas.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaLandmark className="mr-2" /> Pontes</li>
                                    <li><FaLandmark className="mr-2" /> Viadutos</li>
                                    <li><FaLandmark className="mr-2" /> Passarelas</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={6} lg={4} className="mb-4">
                        <Card onClick={() => handleSelectType('saneamento')} className="text-center card-custom" style={{ cursor: 'pointer' }}>
                            <Card.Body>
                                <Card.Title className="mb-3">Saneamento</Card.Title>
                                <Card.Text className="mb-3">
                                    Projetos de sistemas de água e esgoto, incluindo tratamento de águas residuais.
                                </Card.Text>
                                <ul className="list-unstyled text-left pl-3">
                                    <li><FaRecycle className="mr-2" /> Sistema de abastecimento de água</li>
                                    <li><FaRecycle className="mr-2" /> Sistema de esgotamento sanitário</li>
                                    <li><FaRecycle className="mr-2" /> Tratamento de águas residuais</li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Button variant="secondary" onClick={() => navigate(-1)} className="mb-4">
                Voltar
                </Button>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Criar Nova Requisição - {renderModalTitle()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {renderModalContent()}
                </Modal.Body>
            </Modal>

            <Footer1 />
        </>
    );
};

export default SelectInfrastructureType;
