import React, { useState, useEffect } from 'react';
import axios from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';

const SliderConfig = () => {
    const [slides, setSlides] = useState([]);
    const [formData, setFormData] = useState({ image: '', slideTitle: '', description: '' });
    const [editingSlide, setEditingSlide] = useState(null); // Novo estado para slide sendo editado
    const [showModal, setShowModal] = useState(false); // Estado para controlar o modal de edição
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await axios.get('/api/slide');
                setSlides(response.data);
            } catch (error) {
                console.error('Error fetching slides:', error);
            }
        };

        fetchSlides();
    }, []);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editingSlide) {
                // Atualizar o slide existente
                await axios.put(`/api/slide/${editingSlide._id}`, formData);
                alert('Slide updated successfully');
            } else {
                // Adicionar um novo slide
                await axios.post('/api/slide', formData);
                alert('Slide added successfully');
            }
            navigate(0); // Recarrega a página para refletir as mudanças
        } catch (error) {
            console.error('Error saving slide:', error);
        }
    };

    const handleEdit = (slide) => {
        setEditingSlide(slide);
        setFormData({ image: slide.image, slideTitle: slide.slideTitle, description: slide.description });
        setShowModal(true); // Mostrar o modal para edição
    };

    const handleDelete = async (slideId) => {
        try {
            await axios.delete(`/api/slide/${slideId}`);
            alert('Slide deleted successfully');
            setSlides(slides.filter(slide => slide._id !== slideId));
        } catch (error) {
            console.error('Error deleting slide:', error);
        }
    };

    return (
        <>
            <Navbar1 />
            <div className="container mt-5">
                <h2>Configuração do Slider</h2>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>URL da imagem (2000x1333 px - https://imgur.com)</Form.Label>
                        <Form.Control
                            type="text"
                            name="image"
                            value={formData.image}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Título da Imagem</Form.Label>
                        <Form.Control
                            type="text"
                            name="slideTitle"
                            value={formData.slideTitle}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Descrição da Imagem</Form.Label>
                        <Form.Control
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        {editingSlide ? 'Salvar Alterações' : 'Adicionar Slide'}
                    </Button>
                </Form>

                <div className="mt-5">
                    <h4>Slides Existentes</h4>
                    <div className="slides-container">
                        {slides.map(slide => (
                            <div key={slide._id} className="slide-item">
                                <img src={slide.image} alt={slide.slideTitle} width="150" />
                                <h5>{slide.slideTitle}</h5>
                                <p>{slide.description}</p>
                                <Button variant="secondary" onClick={() => handleEdit(slide)}>Editar</Button>
                                <Button variant="danger" onClick={() => handleDelete(slide._id)}>Deletar</Button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {/* Modal para edição do slide */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Slide</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>URL da imagem</Form.Label>
                            <Form.Control
                                type="text"
                                name="image"
                                value={formData.image}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Título da Imagem</Form.Label>
                            <Form.Control
                                type="text"
                                name="slideTitle"
                                value={formData.slideTitle}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Descrição da Imagem</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Salvar Alterações
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Footer1 />
        </>
    );
};

export default SliderConfig;
