// src/utils/fetchMunicipalities.js
export const fetchMunicipalities = async () => {
    try {
        const response = await fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/MT/municipios');
        const data = await response.json();
        return data.map(municipio => municipio.nome).sort((a, b) => a.localeCompare(b));
    } catch (error) {
        console.error('Erro ao carregar os municípios:', error);
        return [];
    }
};
