import React, { useEffect, useState, useContext } from 'react';
import axios from '../axiosConfig';
import AuthContext from '../context/AuthContext';
import Navbar1 from '../components/Navbar1';
import Footer1 from '../components/Footer1';
import { useNavigate } from 'react-router-dom';
import { fetchMunicipalities } from '../utils/fetchMunicipalities';

const UserManagement = () => {
    const { logout } = useContext(AuthContext);
    const [allUsers, setAllUsers] = useState([]);
    const [pendingUsers, setPendingUsers] = useState([]);
    const [inactiveUsers, setInactiveUsers] = useState([]);  // Novo estado para usuários inativos
    const [municipios, setMunicipios] = useState([]);
    const [filtros, setFiltros] = useState({
        municipio: '',
        nomeUsuario: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
        loadMunicipios();
    }, []);

    const fetchUsers = async () => {
        try {
            const resAllUsers = await axios.get('/api/auth/all-users');
            const resPendingUsers = await axios.get('/api/auth/pending-users');
    
            console.log("All Users:", resAllUsers.data);
            console.log("Pending Users:", resPendingUsers.data);
    
            // Filtrar usuários aprovados e inativos
            const approvedUsers = resAllUsers.data.filter(user => user.approved && !user.inactive);
            const inactives = resAllUsers.data.filter(user => user.inactive);
            const pendingUsersFiltered = resPendingUsers.data.filter(user => !user.inactive);  // Excluir inativos
    
            setAllUsers(approvedUsers);
            setPendingUsers(pendingUsersFiltered);
            setInactiveUsers(inactives);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    

    const loadMunicipios = async () => {
        const municipios = await fetchMunicipalities();
        setMunicipios(municipios);
    };

    const handleApprove = async (userId) => {
        console.log(`Approving user with ID: ${userId}`);
        try {
            await axios.put(`/api/auth/approve/${userId}`);
            const updatedPendingUsers = pendingUsers.filter(user => user._id !== userId);
            const approvedUser = pendingUsers.find(user => user._id === userId);

            setPendingUsers(updatedPendingUsers);
            setAllUsers([...allUsers, { ...approvedUser, approved: true }]);
        } catch (error) {
            console.error('Error approving user:', error);
        }
    };

    const handleReject = async (userId) => {
        console.log(`Rejecting user with ID: ${userId}`);
        try {
            await axios.put(`/api/auth/reject/${userId}`);
            const updatedPendingUsers = pendingUsers.filter(user => user._id !== userId);
            setPendingUsers(updatedPendingUsers);
        } catch (error) {
            console.error('Error rejecting user:', error);
        }
    };

    const handleEdit = (userId) => {
        navigate(`/edit-user/${userId}`);
    };

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const handleInactivate = async (userId) => {
        console.log(`Inactivating user with ID: ${userId}`);
        try {
            await axios.put(`/api/auth/user/${userId}/inactivate`);
            fetchUsers(); 
        } catch (error) {
            console.error('Error inactivating user:', error);
        }
    };

    const handleFilterChange = (e) => {
        setFiltros({
            ...filtros,
            [e.target.name]: e.target.value
        });
    };

    const handleActivate = async (userId) => {
        console.log(`Activating user with ID: ${userId}`);
        try {
            await axios.put(`/api/auth/user/${userId}/activate`);
            fetchUsers();  
        } catch (error) {
            console.error('Error activating user:', error);
        }
    };
    
    const applyFilters = (user) => {
        const { municipio, nomeUsuario } = filtros;

        const matchesMunicipio = !municipio || (user.municipality && user.municipality.toLowerCase().includes(municipio.toLowerCase()));
        const matchesNomeUsuario = !nomeUsuario || (user.fullName && user.fullName.toLowerCase().includes(nomeUsuario.toLowerCase()));

        return matchesMunicipio && matchesNomeUsuario;
    };

    return (
        <>
            <Navbar1 />
            <div className="container mt-5">
                <h2>Gerenciamento de Usuários</h2>
    
                <div className="row mb-4">
                    <div className="col-md-4">
                        <label>Município</label>
                        <input
                            type="text"
                            className="form-control"
                            name="municipio"
                            value={filtros.municipio}
                            onChange={handleFilterChange}
                            list="municipios-list"
                            placeholder="Digite para filtrar por município"
                        />
                        <datalist id="municipios-list">
                            {municipios.map((municipio, index) => (
                                <option key={index} value={municipio} />
                            ))}
                        </datalist>
                    </div>
                    <div className="col-md-4">
                        <label>Nome do Usuário</label>
                        <input
                            type="text"
                            className="form-control"
                            name="nomeUsuario"
                            value={filtros.nomeUsuario}
                            onChange={handleFilterChange}
                            placeholder="Digite para filtrar por nome de usuário"
                        />
                    </div>
                </div>
    
                <div className="card mt-4">
    <div className="card-header">
        Todos os Usuários
    </div>
    <div className="card-body">
        {allUsers.filter(applyFilters).length > 0 ? (
            <table className="table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Município</th> {/* Adicionei esta linha */}
                        <th>Cargo</th>
                        <th>Status</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {allUsers.filter(applyFilters).map(user => (
                        <tr key={user._id}>
                            <td>{user.fullName || 'N/A'}</td>
                            <td>{user.email || 'N/A'}</td>
                            <td>{user.municipality || 'N/A'}</td> {/* Adicionei esta linha */}
                            <td>{user.jobTitle || 'N/A'}</td>
                            <td>{user.approved ? 'Ativo' : 'Inativo'}</td>
                            <td>
                                <button className="btn btn-primary me-2" onClick={() => handleEdit(user._id)}>Editar</button>
                                <button className="btn btn-danger me-2" onClick={() => handleInactivate(user._id)}>Inativar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <p>Usuários não encontrados.</p>
        )}
    </div>
</div>

    
<div className="card mt-4">
    <div className="card-header">
        Usuários Pendentes
    </div>
    <div className="card-body">
        {pendingUsers.length > 0 ? (
            <table className="table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Município</th> {/* Adicionei esta linha */}
                        <th>Cargo</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {pendingUsers.map(user => (
                        <tr key={user._id}>
                            <td>{user.fullName || 'N/A'}</td>
                            <td>{user.email || 'N/A'}</td>
                            <td>{user.municipality || 'N/A'}</td> {/* Adicionei esta linha */}
                            <td>{user.jobTitle || 'N/A'}</td>
                            <td>
                                <button className="btn btn-success me-2" onClick={() => handleApprove(user._id)}>Aprovar</button>
                                <button className="btn btn-danger" onClick={() => handleReject(user._id)}>Rejeitar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <p>Sem usuários pendentens de aprovação.</p>
        )}
    </div>
</div>
 
<div className="card mt-4">
    <div className="card-header">
        Usuários Inativos
    </div>
    <div className="card-body">
        {inactiveUsers.length > 0 ? (
            <table className="table">
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Email</th>
                        <th>Município</th> {/* Adicionei esta linha */}
                        <th>Cargo</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {inactiveUsers.map(user => (
                        <tr key={user._id}>
                            <td>{user.fullName || 'N/A'}</td>
                            <td>{user.email || 'N/A'}</td>
                            <td>{user.municipality || 'N/A'}</td> {/* Adicionei esta linha */}
                            <td>{user.jobTitle || 'N/A'}</td>
                            <td>
                                <button className="btn btn-primary me-2" onClick={() => handleEdit(user._id)}>Edit</button>
                                <button className="btn btn-success me-2" onClick={() => handleActivate(user._id)}>Ativar</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : (
            <p>Sem usuários inativos.</p>
        )}
    </div>
</div>

    
                <button onClick={handleLogout} className="btn btn-danger mt-3">Logout</button>
            </div>
            <Footer1 />
        </>
    );
};

export default UserManagement;

