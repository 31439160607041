// Path: AMM\sgp\src\pages\Login.js

import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Alert, Form } from 'react-bootstrap';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import AuthContext from '../context/AuthContext';
import AuthLayout from '../components/AuthLayout';

const Login = () => {
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();
    const [error, setError] = useState('');

    const schema = yup.object().shape({
        email: yup.string().required('Por favor, insira seu email').email('Por favor, insira um email válido'),
        password: yup.string().required('Por favor, insira sua senha')
    });

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data) => {
        try {
            const user = await login(data.email, data.password);
            if (user.role === 'admin') {
                navigate('/admin-dashboard');
            } else {
                navigate('/dashboard');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Erro ao fazer login');
        }
    };

    return (
        <AuthLayout
            hasSlider
            bottomLinks={
                <p className="text-muted">
                    Não tem uma conta? <Link to="/register" className="text-primary fw-semibold ms-1">Registrar</Link>
                </p>
            }
        >
            <h6 className="h5 mb-0 mt-3">Bem-vindo de volta!</h6>
            <p className="text-muted mt-1 mb-4">Digite seu email e senha para acessar o painel.</p>

            {error && (
                <Alert variant="danger" className="mb-3">
                    {error}
                </Alert>
            )}

            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => <Form.Control type="email" {...field} />}
                    />
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Label>Senha</Form.Label>
                    <Controller
                        name="password"
                        control={control}
                        render={({ field }) => <Form.Control type="password" {...field} />}
                    />
                </Form.Group>

                <div className="mb-0 text-center d-grid">
                    <Button type="submit">Login</Button>
                </div>
            </Form>
        </AuthLayout>
    );
};

export default Login;
