// src/components/PendenciasModal.js
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from '../axiosConfig';

const PendenciasModal = ({ protocoloId, fieldName, show, onHide, onIssueAdded }) => {
    const [issueDescription, setIssueDescription] = useState('');

    const handleAddIssue = async () => {
        const currentDateTime = new Date().toLocaleString();
        const fullDescription = `${currentDateTime}: ${issueDescription}`;

        try {
            const response = await axios.put(`/api/estudo-hidrologico/${protocoloId}/pendencias`, {
                fieldName,
                description: fullDescription,
            });
            console.log('Pendência adicionada:', response.data);
            setIssueDescription(''); // Reset the description
            onIssueAdded(); // Callback to notify parent component
            onHide(); // Close the modal
        } catch (error) {
            console.error('Erro ao adicionar pendência:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Adicionar Pendência</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Pendência para: {fieldName}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={issueDescription}
                        onChange={(e) => setIssueDescription(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleAddIssue}>
                    Adicionar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PendenciasModal;
