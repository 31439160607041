import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import AdminDashboard from './pages/AdminDashboard';
import Register from './pages/Register';
import Login from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import AuthContext from './context/AuthContext';
import UserManagement from './pages/UserManagement';
import EditUser from './pages/EditUser';
import SelectRequestType from './pages/SelectRequestType';
import ProtocoloView from './pages/ProtocoloView';
import AdminProtocoloView from './pages/AdminProtocoloView';
import './assets/css/dashboard.css';
import SliderConfig from './pages/SliderConfig';
import ManageUser from './pages/ManageUser';
import SelectInfrastructureType from './pages/SelectInfrastructureType';
import SelectAmbientalType from './pages/SelectAmbientalType';
import UploadTestPage from './pages/UploadTestPage';

const App = () => {
    const { user, loading } = useContext(AuthContext);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={
                    user ? (
                        user.role === 'admin' ? (
                            <Navigate to="/admin-dashboard" />
                        ) : (
                            <Navigate to="/dashboard" />
                        )
                    ) : (
                        <Navigate to="/login" />
                    )
                } />
                <Route path="/login" element={
                    user ? (
                        user.role === 'admin' ? (
                            <Navigate to="/admin-dashboard" />
                        ) : (
                            <Navigate to="/dashboard" />
                        )
                    ) : (
                        <Login />
                    )
                } />
                <Route path="/dashboard" element={<PrivateRoute role="user"><Dashboard /></PrivateRoute>} />
                <Route path="/admin-dashboard" element={<PrivateRoute role="admin"><AdminDashboard /></PrivateRoute>} />
                <Route path="/register" element={<Register />} />
                <Route path="/user-management" element={<PrivateRoute role="admin"><UserManagement /></PrivateRoute>} />
                <Route path="/edit-user/:id" element={<PrivateRoute role="admin"><EditUser /></PrivateRoute>} />
                <Route path="/select-request-type" element={<PrivateRoute role="user"><SelectRequestType /></PrivateRoute>} />
                <Route path="/protocolo/:id" element={<PrivateRoute><ProtocoloView /></PrivateRoute>} />
                <Route path="/admin/protocolo/:id" element={<PrivateRoute role="admin"><AdminProtocoloView /></PrivateRoute>} />
                <Route path="/manage-user" element={<ManageUser />} />
                <Route path="/slider-config" element={<PrivateRoute role="admin"><SliderConfig /></PrivateRoute>} />
                <Route path="/select-infrastructure-type" element={<PrivateRoute role="user"><SelectInfrastructureType /></PrivateRoute>} />
                <Route path="/select-ambiental-type" element={<PrivateRoute role="user"><SelectAmbientalType /></PrivateRoute>} />
                <Route path="/teste" element={<UploadTestPage />} />
            </Routes>
        </Router>
    );
};

export default App;
